import { IDR } from '@/libs/currency'

/* eslint-disable import/prefer-default-export */
export const performaMitraFields = [
  {
    key: 'warehouse_name',
    label: 'Mitra Gudang',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_address',
    label: 'Kota',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_outbound',
    label: 'Outbond',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
  },
  {
    key: 'warehouse_omzet',
    label: 'Omset',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1 align-top',
    sortable: true,
    formatter: value => IDR(value).replace('.', ''),
  },
]
