<template>
  <BRow>
    <BCol lg="12">
      <div class="text-[32px] text-[#222222] font-bold mb-[24px]">
        Dashboard
      </div>
      <BCard body>
        <div class="font-[600] text-[#222222] text-[20px]">
          Performa Pertumbuhan User (Partner)
        </div>
        <div class="mt-1 grid grid-cols-4 grid-rows-2 gap-4">
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="@/assets/images/svg/note.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Registrasi</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.registration.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.registration.this_week }} Minggu ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.registration.last_week }} Minggu kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="@/assets/images/svg/tick-square.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Ajukan Berlangganan</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.subscribe_application.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.subscribe_application.this_week }} Minggu ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.subscribe_application.last_week }} Minggu kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/onboarding.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Berlangganan</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.subscription.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.subscription.this_week }} Minggu ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.subscription.last_week }} Minggu kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/maximize-circle-twotone.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Inbound</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.inbound.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.inbound.this_week }} Minggu ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.inbound.last_week }} Minggu kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/clipboard-tick-twotone.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Aktif</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.active.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.active.today }} Hari ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.active.yesterday }} Kemarin
                </div>
              </div>
            </div>
            <div class="flex space-x-2 mt-[12px]">
              <div class="text-sm text-black">
                Bulan lalu: <span class="text-[#08A0F7]">+ {{ data.active.last_month }}</span>
              </div>
              <div class="text-sm text-black">
                Bulan ini: <span class="text-[#34A270]">+ {{ data.active.this_month }}</span>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/clipboard-import-twotone.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">In-aktif</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-1">
                {{ data.inactive.total }}
                <div class="text-sm text-[10px] font-[400]">Lifetime</div>
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  + {{ data.inactive.this_month }} Bulan ini
                </div>
                <div class="text-[#08A0F7]">
                  + {{ data.inactive.last_month }} Bulan kemarin
                </div>
              </div>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/shop-remove-twotone.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Pasif</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="mt-2 flex"
            >
              <span class="text-black text-[24px] font-bold pr-2">
                {{ data.passive.total }}
              </span>
            </div>
          </div>
          <div class="border border-[#e2e2e2] rounded-2xl  p-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <img
                  src="https://storage.googleapis.com/komerce/assets/svg/activity-twotone.svg"
                  alt="Komerce"
                >
                <span class="text-[14px] font-bold text-black ml-[5px]">Aktif Bulan ini</span>
              </div>
            </div>
            <BSpinner
              v-if="loading"
              small
              variant="primary"
            />
            <div
              v-else
              class="my-[10px] flex"
            >
              <span class="text-black text-[24px] font-bold border-r pr-2">
                {{ data.active_this_month.total }}
              </span>
              <div class="ml-1">
                <div class="text-[#34A270]">
                  {{ Math.round(data.active_this_month.new_users_percentage) }}% User Baru ({{ data.active_this_month.new_users }})
                </div>
                <div class="text-[#08A0F7]">
                  {{ Math.round(data.active_this_month.old_users_percentage) }}% User Lama ({{ data.active_this_month.old_users }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </BCard>
      <BCard body>
        <div class="space-y-[24px]">
          <div class="font-[600] text-[#222222] text-[20px]">
            Performa Mitra Gudang
          </div>
          <div class="bg-primary text-white p-[12px] rounded-[8px]">
            <div class="flex items-center gap-[10px]">
              <div>Total Penghasilan Lifetime</div>
              <img
                id="lifetimeIncome"
                class="w-[24px] h-[24px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/info-circle-1.svg"
              >
              <BPopover
                target="lifetimeIncome"
                triggers="hover"
                placement="top"
              >Total penghasilan Kompack</BPopover>
            </div>
            <div class="text-[32px] font-bold text-white mt-[10px]">
              {{ IDR(dataPerforma.lifetime_income).substring(3) }},-
            </div>
          </div>
          <div class="flex justify-between mb-[16px]">
            <div class="border p-[12px]">
              <div class="flex items-center gap-[10px]">
                <div class="text-[#222222] font-bold">
                  Total Penghasilan
                </div>
                <img
                  id="onFilterIncome"
                  class="w-[24px] h-[24px]"
                  src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                >
                <BPopover
                  target="onFilterIncome"
                  triggers="hover"
                  placement="top"
                >Total penghasilan Kompack berdasarkan rentang waktu terpilih</BPopover>
              </div>
              <div class="text-success text-[32px] font-bold mt-[10px]">
                {{ IDR(dataPerforma.by_filter_income).substring(3) }},-
              </div>
            </div>
            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'left'"
              class="withdrawal-date-range"
            >
              <template v-slot:input="picker">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <img
                    src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
                    alt="Komerce"
                  >
                  <div class="ml-1">
                    <span
                      style="color: #828282 !important"
                    >
                      {{ DAY_MONTH_YEAR(picker.startDate) }} - {{ DAY_MONTH_YEAR(picker.endDate) }}
                    </span>
                  </div>
                </div>
              </template>
            </DateRangePicker>
          </div>
          <BOverlay
            :show="performaLoading"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BTable
              :items="dataPerforma.data_mitra.data"
              :fields="performaMitraFields"
              show-empty
              empty-text="Tidak ada data yang ditampilkan."
              responsive
              class="mt-1"
            >
              <template #cell(warehouse_name)="row">
                <div class="flex gap-[8px]">
                  <img
                    v-if="!row.item.warehouse_image"
                    src="https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px]"
                  >
                  <img
                    v-else
                    :src="row.item.warehouse_image"
                    alt="fotoGudang"
                    class="h-[38px] w-[38px] object-cover"
                  >
                  <div class="">
                    <div class="text-[#222222]">
                      {{ row.item.warehouse_name }}
                    </div>
                    <div class="text-primary text-[12px ]">
                      {{ row.item.warehouse_owner }}
                    </div>
                  </div>
                </div>
              </template>
            </BTable>
          </BOverlay>
        </div>
        <BRow>
          <BCol
            cols="12"
            class="d-flex justify-content-between"
          >
            <div
              class="d-flex justify-content-center align-items-center"
            >
              <span class="text-black mr-50">List per halaman: </span>
              <div class="rounded bg-light px-[8px] py-[4px]">
                <BButton
                  v-for="page in optionsPage"
                  :key="page"
                  class="btn-icon"
                  size="sm"
                  :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
                  @click="setPerPage(page)"
                >
                  {{ page }}
                </BButton>
              </div>
            </div>

            <BPagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="totalPerPage"
              first-number
              last-number
              class="pagination-primary"
            >
              <template #prev-text>
                <feather-icon
                  size="18"
                  icon="ChevronLeftIcon"
                />
              </template>
              <template #next-text>
                <feather-icon
                  size="18"
                  icon="ChevronRightIcon"
                />
              </template>
            </BPagination>
          </BCol>
        </BRow>
      </BCard>
    </BCol>
  </BRow>
</template>
<script>
import { axiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  lastDateOfMonth,
  last30,
  last7,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, DAY_MONTH_YEAR, YEAR_MONTH_DAY,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import { performaMitraFields } from './config'

export default {
  components: { DateRangePicker },
  data() {
    return {
      data: {},
      loading: false,
      performaLoading: false,
      dateRange: {
        startDate: last7,
        endDate: today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        '7 Hari Terakhir': [last7, today],
        '1 Bulan terakhir': [last30, today],
        'Bulan ini': [firstDateOfMonth, lastDateOfMonth],
        'Custom Tanggal ': [today, today],
      },
      firstDateOfMonth,
      last30,
      last7,
      today,
      YEAR_MONTH_DAY,
      DAY_MONTH_YEAR,
      IDR,
      performaMitraFields,
      dataPerforma: {},
      optionsPage: [20, 50, 100, 200, 400],
      totalPerPage: 20,
      currentPage: 1,
      totalRows: 0,
    }
  },
  watch: {
    totalPerPage: {
      handler() {
        this.getDataPerforma()
      },
    },
    currentPage: {
      handler() {
        this.getDataPerforma()
      },
    },
    dateRange: {
      handler() {
        this.getDataPerforma()
      },
    },
  },
  mounted() {
    this.getData()
    this.getDataPerforma()
  },
  methods: {
    async getData() {
      this.loading = true
      const url = '/v1/warehouse/dashboard/partner-growth-performance'
      await axiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.data = data
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: 'Gagal mengambil data, refresh halaman ini',
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    async getDataPerforma() {
      this.performaLoading = true
      const url = '/v1/warehouse/dashboard/mitra-performance'
      const params = {
        total_per_page: this.totalPerPage,
        page: this.currentPage,
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
      }
      await axiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.dataPerforma = data
          this.totalRows = data.data_mitra.total
          this.performaLoading = false
        })
        .catch(err => {
          this.performaLoading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.message,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    setPerPage(page) {
      this.totalPerPage = page
    },
  },
}
</script>
